<script setup lang="ts">
import { useMainStore } from '../store/index.ts';

const config = useConfig();

const store = useMainStore();
const darkMode = store.darkMode;
</script>

<template>
    <Submenu
        v-if="config.links.enabled && config.links.split"
        :links="[{
            header: 'links.links',
            name: 'links',
            icon: 'bookmark',
            condition: config.links.links.length > 0,
        }, {
            header: 'links.academic.header',
            name: 'links-academic',
            icon: 'vial',
            condition: Object.keys(config.links.academic).length > 0,
        }, {
            header: 'links.translinguistics.header',
            name: 'links-translinguistics',
            icon: 'head-side-brain',
            condition: (config.links.translinguisticsRoute || []).length > 0,
        }, {
            header: 'links.zine.header',
            name: 'links-zine',
            icon: 'zine.svg',
            iconInverse: darkMode,
            condition: config.links.zine && config.links.zine.enabled,
        }, {
            header: 'links.mediaShort',
            name: 'links-media',
            icon: 'tv',
            condition: config.links.mediaGuests.length > 1 || config.links.mediaMentions.length > 1,
        }, {
            header: 'faq.header',
            name: 'faq',
            icon: 'map-marker-question',
            condition: config.faq.enabled,
        }, {
            header: 'english.header',
            name: 'english',
            icon: 'globe-americas',
            condition: config.english.enabled,
        }]"
    />
</template>
